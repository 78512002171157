import 'moment/locale/nl.js'
import 'react-datetime/css/react-datetime.css'

import { DATE_FORMAT, formatDateTime } from '../util'
import {
  DataTypeProvider,
  FilteringState,
  GroupingState,
  SortingState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedGrouping,
  IntegratedSummary,
  SummaryState,
  TreeDataState,
} from '@devexpress/dx-react-grid'
import {
  DragDropProvider,
  Grid,
  GroupingPanel,
  Table,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap3'
import {
  Glyphicon,
  InputGroup,
  Button,
} from 'react-bootstrap'
import { connect } from 'react-redux'
import React from 'react'

import { fetchMessages } from '../redux/actions/tracker'
import { getTrackedMessages } from '../redux/selectors'
import Datetime from 'react-datetime'

function Status(props) {
  const { value, row: { changed } } = props

  return value === 'afgerond'
    ?
    <React.Fragment>
      <Glyphicon glyph="ok" title='Afgerond' style={{ color: '#5cb85c' }}/>&nbsp;
      <small style={{ color: 'gray' }}>{formatDateTime(changed)}</small>
    </React.Fragment>
    :
    <Glyphicon glyph="remove" title="Open" style={{ color: '#d9534f' }}/>
}

function StatusTypeProvider(props) {
  return  <DataTypeProvider formatterComponent={Status} {...props} />
}

const groupingMessages = { groupByColumn: 'Sleep hier een kopje naartoe om de kolom te groeperen' }
const filteringMessages = {
  contains: 'Bevat',
  notContains: 'Bevat niet',
  startsWith: 'Begint met',
  endsWith: 'Eindigt met',
  equal: 'Gelijk aan',
  notEqual: 'Ongelijk aan',
}

export class TrackerView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      columns: [
        { name: 'netbeheerder', title: 'Netbeheerder' },
        { name: 'aannemer', title: 'Aannemer' },
        { name: 'bericht', title: 'Bericht' },
        { name: 'versieNummer', title: 'Versie' },
        { name: 'disciplineLabel', title: 'Discipline' },
        { name: 'status', title: 'Status' },
        { name: 'opdrachtId', title: 'Opdracht ID' },
      ],
      rows: props.messages,
      grouping: [{ columnName: 'netbeheerder' },{ columnName: 'aannemer' }],
      tableColumnExtensions: [
        { columnName: 'status', align: 'right' }
      ],
    }
  }

  changeGrouping = grouping => this.setState({ grouping })

  componentDidMount() {
    this.props.fetchMessages()
  }

  componentDidUpdate(prevProps) {
    const { messages } = this.props

    if (prevProps.messages !== messages) {
      const rows = messages.map(m => ({ ...m, status: (m.changed ? 'afgerond' : 'open') }))

      this.setState({ rows })
    }
  }

  refresh = () => {
    let date = this.state.fromDate

    date = date ? date.valueOf() : undefined
    this.props.fetchMessages(date)
  }

  changeFromDate = fromDate => this.setState({ fromDate })

  render() {
    const { rows, columns, grouping, treeSummaryItems } = this.state

    return (
      <div>
        <InputGroup style={{ width: '400px', marginBottom: '5px' }}>
          <InputGroup.Addon>Toon resultaten vanaf</InputGroup.Addon>
          <Datetime dateFormat={DATE_FORMAT} input={true} onChange={this.changeFromDate}/>
          <Button componentClass={InputGroup.Addon} bsStyle="success" onClick={this.refresh}>Verversen</Button>
        </InputGroup>


        <Grid
          rows={rows}
          columns={columns}>
          <StatusTypeProvider for={['status']} />
          <DragDropProvider />

          <SortingState
            defaultSorting={[{ columnName:'opdrachtgever' },{ columnName:'opdrachtnemer' }]}
          />

          <GroupingState
            grouping={grouping}
            onGroupingChange={this.changeGrouping}
          />
          <TreeDataState />
          <FilteringState />
          <SummaryState groupItems={treeSummaryItems} />

          <IntegratedSorting rows={['opdrachtgever' ]} />
          <IntegratedGrouping />
          <IntegratedFiltering/>
          <IntegratedSummary treeItems={treeSummaryItems} />

          <Table />
          <TableHeaderRow showGroupingControls showSortingControls />
          <TableFilterRow showFilterSelector messages={filteringMessages} />
          <TableGroupRow />
          <Toolbar />
          <GroupingPanel showGroupingControls
            messages={groupingMessages} />
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  messages: getTrackedMessages(state)
})

export default connect(mapStateToProps, {
  fetchMessages,
})(TrackerView)
