import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  FormControl,
  Modal,
  ListGroup,
  ListGroupItem,
  Badge,
  InputGroup,
  Glyphicon,
  FormGroup,
  DropdownButton,
  MenuItem, Panel
} from 'react-bootstrap'
import { getDefaultRules, userHasScopes } from '../redux/selectors'
import { createRule, updateRule, deleteRule } from '../redux/actions/rules'

const isNotDefault = entry => entry.default135 === false && entry.default136 === false

class DefaultsForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      aannemertoAdd: '',
      opdrachtnemertoAdd: ''
    }
  }

  onChangeInput = e => this.setState({ [e.target.id]: e.target.value.trimStart() })

  deleteAannemer = entry => this.props.defaultRules
    .filter(rule => rule.aannemer === entry.aannemer)
    .forEach(rule => this.props.deleteRule(rule._id))

  addAannemer = () => {
    const { aannemertoAdd, opdrachtnemertoAdd } = this.state
    const { selectedOpdrachtnemer } = this.props
    const opdrachtnemer = selectedOpdrachtnemer === 'Nieuw' ? opdrachtnemertoAdd : selectedOpdrachtnemer

    if(aannemertoAdd && aannemertoAdd !== '' && opdrachtnemer && opdrachtnemer !== '') {
      this.props.createRule({ versieNummer: '1.35', default: false, aannemer: aannemertoAdd.trim(), opdrachtnemer: opdrachtnemer.trim() })
      this.props.createRule({ versieNummer: '1.36', default: false, aannemer: aannemertoAdd.trim(), opdrachtnemer: opdrachtnemer.trim() })

      this.setState({ aannemertoAdd: '' , opdrachtnemertoAdd: '' })
    }
  }

  handleDragStart = versieNummer => e => {
    if(this.props.draftMode === true)
      e.dataTransfer.setData('text/plain', versieNummer)
  }

  handleDrop = entry => e => {
    if(this.props.draftMode === true) {
      const versieNummer = e.dataTransfer.getData('text')

      const currentDefaultRule = this.props.defaultRules.find(rule => rule.default === true && rule.versieNummer === versieNummer)
      const newDefaultRule = this.props.defaultRules.find(rule => rule.aannemer === entry.aannemer && rule.versieNummer === versieNummer)

      currentDefaultRule.default = false
      newDefaultRule.default = true

      this.props.updateRule(currentDefaultRule)
      this.props.updateRule(newDefaultRule)
    }
  }

  groupByAannemerVersienummer = (acc, rule) => {
    const defaultVersion = 'default' + rule.versieNummer.replace('.','')

    acc[rule.aannemer] = { ...acc[rule.aannemer],  aannemer: rule.aannemer, [defaultVersion]: rule.default }

    return acc
  }

  renderBeheer = () => {
    const { opdrachtnemers, selectedOpdrachtnemer, draftMode, userHasScopes } = this.props
    const { aannemertoAdd, opdrachtnemertoAdd } = this.state
    const showBeheer = userHasScopes(['show:swv-beheer'])

    return showBeheer &&
      <div style={{ paddingBottom: '10px' }}>
        <FormGroup>
          <InputGroup>
            <DropdownButton
              componentClass={InputGroup.Button}
              id="input-dropdown-swv"
              title={selectedOpdrachtnemer ? selectedOpdrachtnemer : 'Kies' }
              onSelect={this.props.onSelectOpdrachtnemer}>
              { draftMode && <MenuItem key="Nieuw" eventKey="Nieuw">Nieuw</MenuItem> }
              { opdrachtnemers.map(v =>
                <MenuItem key={v} eventKey={v}>{v}</MenuItem>
              )}
            </DropdownButton>
            <FormControl id="opdrachtnemertoAdd" type="text" disabled={selectedOpdrachtnemer !== 'Nieuw'}
              placeholder="Opdrachtnemer" onChange={this.onChangeInput} value={opdrachtnemertoAdd}/>
          </InputGroup>
        </FormGroup>
        { draftMode && <FormGroup>
          <InputGroup>
            <FormControl id="aannemertoAdd" type="text" onChange={this.onChangeInput} placeholder="Aannemer" value={aannemertoAdd}/>
            <InputGroup.Button>
              <Button onClick={this.addAannemer}><Glyphicon glyph='plus'></Glyphicon></Button>
            </InputGroup.Button>
          </InputGroup>
        </FormGroup>}
      </div>
  }


  render() {
    const { defaultRules, selectedOpdrachtnemer, userHasScopes, draftMode } = this.props
    const showBeheer = userHasScopes(['show:swv-beheer']) && draftMode
    const showDefaults = selectedOpdrachtnemer !== 'Nieuw' && selectedOpdrachtnemer !== undefined
    const items = Object.values(defaultRules
      .filter(rule => rule.opdrachtnemer === selectedOpdrachtnemer)
      .reduce(this.groupByAannemerVersienummer, {}))

    return (
      this.props.show
        ?  <div className="static-modal">
          <Modal.Dialog>
            <Modal.Header>
              <Modal.Title>Samenwerkingsverband bewerken</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { this.renderBeheer() }
              { showDefaults &&
              <div>
                <Panel bsStyle="primary">
                  <Panel.Heading>
                    <Panel.Title>
                      <strong>{selectedOpdrachtnemer}</strong>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <ListGroup>
                      { items.map(entry => {
                        return (
                          <ListGroupItem key={entry.aannemer} onDragOver={(e) => e.preventDefault()} onDrop={this.handleDrop(entry)}>{entry.aannemer}
                            { showBeheer && isNotDefault(entry) &&
                                <Badge onClick={() => this.deleteAannemer(entry)} style={{ backgroundColor: '#d9534f' }}><Glyphicon glyph='trash'></Glyphicon></Badge>}
                            { entry.default135 === true && <Badge draggable onDragStart={this.handleDragStart('1.35')} >Default 1.35</Badge>}
                            { entry.default136 === true && <Badge draggable onDragStart={this.handleDragStart('1.36')} >Default 1.36</Badge>}
                          </ListGroupItem>
                        )}
                      )}
                    </ListGroup>
                  </Panel.Body>
                </Panel>
              </div>}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.props.onClose}>Sluiten</Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
        : false
    )}
}

DefaultsForm.propTypes = {
  show: PropTypes.bool,
  draftMode: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectedOpdrachtnemer: PropTypes.string,
  onSelectOpdrachtnemer: PropTypes.func.isRequired,
  opdrachtnemers: PropTypes.array
}

DefaultsForm.defaultProps = {
  show: false,
  draftMode: false
}

const mapStateToProps = state => ({
  defaultRules: getDefaultRules(state),
  userHasScopes: userHasScopes(state),
})

export default connect(mapStateToProps, {
  createRule,
  updateRule,
  deleteRule
})(DefaultsForm)
