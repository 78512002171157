import React from 'react'
import './Cells.css'
import 'react-datetime/css/react-datetime.css'
import Datetime from 'react-datetime'
import moment from 'moment'
import 'moment/locale/nl.js'

import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { Link } from 'react-router-dom'
import { DATE_FORMAT, formatDateTime } from '../util'

import {
  DropdownButton,
  MenuItem,
} from 'react-bootstrap'

import {
  getAannemers,
  getNetbeheerders,
  getDisciplineByRole,
  getVersieNummers,
} from '../redux/selectors'
import { connect } from 'react-redux'
function DateFormatter({ value }) {
  return (value && value !== '') ? formatDateTime(value) : ''
}

export function DateTypeProvider(props) {
  return <DataTypeProvider formatterComponent={DateFormatter} {...props} />
}

export function DateFilterCell({ filter, onFilter }) {
  return (
    <th colSpan="1" style={{ fontWeight: 'normal' }}>
      <Datetime
        dateFormat={DATE_FORMAT}
        inputProps={{ placeholder: 'Filter...' }}
        input={true}
        timeFormat={false}
        closeOnSelect={true}
        isValidDate={(current, selected) => moment().isAfter(current)}
        onChange={m => (m && !m.format) || onFilter(m ? { value: m.format() } : null) }
      />
    </th>
  )
}

export function LinkFormatter({ row }) {
  return <Link to={`/status/${row.opdrachtId}`}>{row.opdrachtId}</Link>
}

export function OpdrachtLinkProvider(props) {
  return <DataTypeProvider formatterComponent={LinkFormatter} {...props} />
}

export function DropdownFilterCell(props) {
  const { id, filter, onFilter, items = {} } = props

  return (
    <th colSpan="1" style={{ fontWeight: 'normal' }} className="dropdown">
      <DropdownButton id={id}
        title={(filter && items[filter.value]) || 'Selecteer'}
        onSelect={value => onFilter(value && value !== '-' ? { value } : null)}>
        { Object.entries(items).map(([ k, label ]) =>
          <MenuItem key={k} eventKey={k} active={filter && k === filter.value}>{label}</MenuItem>
        )}
      </DropdownButton>
    </th>
  )
}

export const AannemerFilterCell = connect(state => ({ id:'aannnemers', items:getAannemers(state) }))(DropdownFilterCell)
export const NetbeheerderFilterCell = connect(state => ({ id:'netbeheerders', items:getNetbeheerders(state) }))(DropdownFilterCell)
export const VersieFilterCell = connect(state => ({ id:'versies', items:getVersieNummers(state) }))(DropdownFilterCell)
export const DisciplineFilterCell = connect(state => ({ id:'dicsipilnes', items:getDisciplineByRole(state) }))(DropdownFilterCell)
export const StatusFilterCell = props => <DropdownFilterCell id={'statusText'} items={{ '-':'Alle', 'open':'Open' }} {...props}  />
