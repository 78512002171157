import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'

const CertificateTable = ({ children, onCreate }) =>
  <Table striped bordered condensed hover>
    <thead>
      <tr>
        <th>Bestandsnaam</th>
        <th>Soort</th>
        <th width="150">Aangemaakt</th>
        <td colSpan={2} align="center">&nbsp;</td>
      </tr>
    </thead>
    <tbody>
      { children }
    </tbody>
  </Table>

CertificateTable.propTypes = {
  onCreate: PropTypes.func,
}

export default CertificateTable
