import {
  DropdownButton,
  Grid,
  MenuItem,
  PanelGroup,
  Tabs, Tab,
  ToggleButtonGroup, ToggleButton,
} from 'react-bootstrap'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'

import PropTypes from 'prop-types'

import {
  fetchEndpoints, saveEndpoint,
  fetchParties,
} from '../redux/actions/endpoints'
import {
  fetchCertificates, saveCertificate,
} from '../redux/actions/certificates'
import {
  getCertificatesBySystem,
  getEndpointsBySystem,
  getSelectedVersion,
  getSelectedParty,
  getUserParty,
  getUserRoles,
  getUserHasLip,
  userHasScopes,
}  from '../redux/selectors'
import EndpointsForm from './EndpointsForm'
import Certificates from './Certificates'
// import RemarksForm from './RemarksForm'
import {
  storeFileForDownload,
  selectVersion,
  selectParty,
} from '../redux/actions/page'

import  PartySelector  from './PartySelector'

const endpoints = {
  netbeheerder: ['Bijstelling', 'Planning', 'TechnischGereed', 'AGAssets', 'AGProductiestaat', 'AnnuleringGereed'],
  aannemer: ['Opdracht', 'BeoordelingTechnischGereed', 'BeoordelingAGAssets', 'BeoordelingAGProductiestaat', 'OpdrachtGereed', 'Annulering']
}

const lipEndpoints = {
  dsp: ['Pull', 'Track & Trace', 'IntakeCallback'],
  deelnemer: ['Aanvraag', 'Intake']
}

const dspVersions = ['1.35', '1.36']
const lipVersions = ['LIP v0103']

export class Connectivity extends Component {
  static propTypes = {
    endpoints: PropTypes.object.isRequired,
    certificates: PropTypes.object.isRequired,
  }
  static defaultProps = {
    endpoints: {},
    certificates: {},
  }
  state = {
    showEmpty: true,
  }
  saveEndpointFunctions = {}

  isBeheer = () => this.props.roles && (this.props.roles.includes('Beheer') || this.props.roles.includes('Beheer ziet alles'))

  determineEndpoints = () => {
    const props = this.props
    const roles = props.roles || []

    let endpointNames = []
    let dspEndpointNames = []

    if (props.selectedVersion.includes('LIP')) {
      if (this.isBeheer()) {
        endpointNames = [].concat(lipEndpoints.deelnemer, lipEndpoints.dsp)
        dspEndpointNames = endpointNames
      } else if (roles.some(r => r.includes('Aannemer')) || roles.includes('Netbeheerder')) {
        endpointNames = [].concat(lipEndpoints.deelnemer)
        dspEndpointNames = [].concat(lipEndpoints.dsp)
      }
    } else {
      if (this.isBeheer()) {
        endpointNames = [].concat(endpoints.aannemer, endpoints.netbeheerder)
        dspEndpointNames = endpointNames
      } else if (roles.some(r => r.includes('Aannemer'))) {
        endpointNames = [].concat(endpoints.aannemer)
        dspEndpointNames = [].concat(endpoints.netbeheerder)
      } else if (roles.includes('Netbeheerder')) {
        endpointNames = [].concat(endpoints.netbeheerder)
        dspEndpointNames = [].concat(endpoints.aannemer)
      }
    }

    this.setState({ endpointNames, dspEndpointNames })
  }

  componentDidMount() {
    const props = this.props
    const { party } = props

    let versions = dspVersions

    if (this.props.userHasScopes(['create:lip']) || this.props.hasLip) {
      versions = versions.concat(lipVersions)
    }
    this.setState({ versions })

    if (!props.selectedVersion) {
      props.selectVersion(dspVersions[0])
    }
    this.props.selectParty(party)
    this.props.fetchParties()
    this.determineEndpoints()
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedVersion !== prevProps.selectedVersion || this.props.roles !== prevProps.roles)
      this.determineEndpoints()


    if (this.props.party !== prevProps.party) {
      const { party } = this.props

      // this.setState({ party })
      this.props.selectParty(party)
      this.props.fetchParties()
    }

  }

  createSaveEndpointFunction = system => endpoint => {
    let party = this.props.party

    if (this.isBeheer()) party = this.props.selectedParty

    this.props.saveEndpoint({ party, version: this.props.selectedVersion, system, endpoint })
  }

  getSaveEndpointFunction = system => {
    if (!this.saveEndpointFunctions[system]) {
      this.saveEndpointFunctions[system] = this.createSaveEndpointFunction(system)
    }

    return this.saveEndpointFunctions[system]
  }

  saveCertificate = certificate => {
    certificate.version = this.props.selectedVersion

    if (this.isBeheer()) certificate.party = this.props.selectedParty

    this.props.saveCertificate(certificate)
  }


  renderTab = (system) => {
    const props = this.props
    const party         = props.selectedParty || props.party
    const version       = props.selectedVersion
    const { endpoints } = props.getEndpointsBySystem(system)  || {}
    const certificates  = props.getCertificatesBySystem(system) || []
    const endpointNames = party === 'DSP' ? this.state.dspEndpointNames : this.state.endpointNames
    const editable      = this.isBeheer() || props.party === props.selectedParty

    return (
      <Tab key={system} eventKey={system} title={system}>
        <PanelGroup id={`panelgroup-${system}`}
          style={{ paddingTop:'5px' }}>
          <EndpointsForm
            key={`e${party}-${version}-${system}`}
            title={ `Endpoints ${party} versie ${version}` }
            filterEmpty={this.state.filterEmpty}
            endpoints={endpoints} endpointNames={endpointNames}
            editable={editable}
            save={this.getSaveEndpointFunction(system)}/>

          <Certificates
            key={`c${party}-${version}-${system}`}
            title={ `Certificaten ${party} versie ${version}` }
            certificates={certificates} party={props.party}
            editable={editable}
            system={system} saveCertificate={this.saveCertificate} />
        </PanelGroup>
      </Tab>
    )
  }

  render() {
    const props = this.props
    const versions = this.state.versions || []

    if (!props.party) return false

    return (
      <Grid>
        <div style={{ textAlign:'right' }}>
          <DropdownButton id="version" title={'Versie ' + props.selectedVersion} onSelect={props.selectVersion} bsSize="small" >
            { versions.map(v =>
              <MenuItem key={v} eventKey={v} active={props.selectedVersion === v}>{v}</MenuItem>
            )}
          </DropdownButton>
          {
            this.isBeheer() &&
              <Fragment>
                <ToggleButtonGroup
                  type="checkbox"
                  bsSize="small"
                  value={this.state.filterEmpty ? [1] : []}
                  onChange={() => this.setState({ filterEmpty:!this.state.filterEmpty })}>
                  <ToggleButton value={1}>Toon alleen onderhouden</ToggleButton>
                </ToggleButtonGroup>&nbsp;
              </Fragment>
          }

          <PartySelector />
        </div>
        <Tabs id="systems" onSelect={this.selectTab}>
          {['Test', 'Acceptatie', 'Productie'].map(this.renderTab)}
        </Tabs>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  getEndpointsBySystem: system => getEndpointsBySystem(state, system),
  getCertificatesBySystem: system => getCertificatesBySystem(state, system),
  selectedVersion: getSelectedVersion(state),
  selectedParty: getSelectedParty(state),
  party: getUserParty(state),
  roles: getUserRoles(state),
  userHasScopes: userHasScopes(state),
  hasLip: getUserHasLip(state),
})

export default connect(mapStateToProps, {
  fetchEndpoints, saveEndpoint,
  fetchCertificates, saveCertificate,
  fetchParties,
  storeFileForDownload,
  selectVersion,
  selectParty,
})(Connectivity)
