import React from 'react'
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  InputGroup,
  Glyphicon,
} from 'react-bootstrap'
import copy from 'copy-to-clipboard'

function EndpointInput({ label, url, action, readOnly, onChange }) {
  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
      <InputGroup style={{ marginBottom: '2px' }} bsSize="small">
        <InputGroup.Addon style={{ width: '8em', textAlign: 'left'  }}>URL</InputGroup.Addon>
        <FormControl type="text" value={url} readOnly={readOnly} onChange={e => onChange('url', e.target.value)} />
        <Button componentClass={InputGroup.Addon} onClick={() => copy(url)}><Glyphicon glyph="copy" /></Button>
      </InputGroup>
      <InputGroup bsSize="small">
        <InputGroup.Addon style={{ width: '8em', textAlign: 'left' }}>SOAP Action</InputGroup.Addon>
        <FormControl type="text" value={action} readOnly={readOnly} onChange={e => onChange('action', e.target.value)} />
        <Button componentClass={InputGroup.Addon} onClick={() => copy(action)}><Glyphicon glyph="copy" /></Button>
      </InputGroup>
    </FormGroup>
  )
}

export default React.memo(EndpointInput)
