import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  HelpBlock,
  Panel,
} from 'react-bootstrap'
import Dropzone from 'react-dropzone'

import CertificatesTable from './CertificatesTable'
import CertificateRow from './CertificateRow'

const  readFile = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()

  reader.onload = () =>  resolve({ name: file.name, content: reader.result })
  reader.onabort = () => reject('file reading was aborted')
  reader.onerror = () => reject('file reading has failed')

  reader.readAsBinaryString(file)
})

export class Certificates extends Component {
  static propTypes = {
    system: PropTypes.string.isRequired,
    party: PropTypes.string.isRequired,
    parties: PropTypes.array,
  }
  static defaultProps = {
    title: 'Certificaten',
    parties: []
  }
  state = {
    certificates: [],
  }

  componentDidUpdate(prevProps, prevState) {
    const props = this.props

    if (prevProps.endpoints !== props.endpoints) {
      const endpoints = props.endpoints || {}

      this.setState({ endpoints })
    }
  }

  getEndpoint = name => this.state.endpoints[name] || { url:'', action:'' }

  createUpdater = name => (prop, value = '') => {
    const newEndpoint = { ...this.getEndpoint(name), [prop]:value }
    const endpoints = { ...this.state.endpoints, [name]:newEndpoint }

    this.setState({ endpoints })
  }

  save = () => {
    this.props.save(this.state.endpoints)
    this.setState({ editmode:false })
  }

  cancel = () => this.setState({ editmode:false, endpoints:this.props.endpoints })

  getUpdater = name => {
    if (!this.updaters[name]) {
      this.updaters[name] = this.createUpdater(name)
    }

    return this.updaters[name]
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles === 0) return

    const { system, party } = this.props

    try {
      for (let file of acceptedFiles) {
        const cert = await readFile(file)

        this.props.saveCertificate({ ...cert, system, party })
      }
    } catch (err) {
      console.error(err)  // eslint-disable-line

      this.setState({ err })
    }
  }

  render() {
    const props = this.props
    const { editmode } = this.state
    const disabled = !editmode

    const accept = '.cer, .crt, .pem, .ca-bundle'

    return (
      <Panel eventKey="certificates" defaultExpanded>
        <Panel.Heading>
          <Panel.Title toggle>{props.title}</Panel.Title>
        </Panel.Heading>

        <Panel.Collapse>
          <Panel.Body>
            <div style={{ textAlign: 'right' }}>
              {disabled && <Button bsSize="small" disabled={!props.editable} onClick={() => this.setState({ editmode:true })}>Wijzigen</Button>}
              {editmode && <Button bsSize="small" onClick={() => this.setState({ editmode:false })}>Annuleren</Button> }
            </div>
            <br/>
            <CertificatesTable editmode={editmode}>
              {props.certificates && props.certificates.map(cert =>
                <CertificateRow key={cert._id} certificate={cert} disabled={disabled} />
              )}
            </CertificatesTable>
          </Panel.Body>
          <Panel.Footer>
            <Dropzone onDrop={this.onDrop} multiple={true} name="message"
              accept={accept}className="form-control"
              style={{ height: '100%' }} disabled={disabled}
              disabledStyle={{ background: '#eee', cursor: 'not-allowed' }}
              activeClassName="primary" acceptClassName="success" rejectClassName="error" >

              <HelpBlock>Sleep hier certificaten naartoe <small>( {accept} )</small></HelpBlock>
            </Dropzone>
          </Panel.Footer>
        </Panel.Collapse>

      </Panel>
    )
  }
}

export default Certificates
