import { debounce } from '../util'
import {
  VirtualTableState,
  FilteringState,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  VirtualTable,
  TableFilterRow,
  TableHeaderRow,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-bootstrap3'
import { Panel, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import React  from 'react'
import {
  getOpdrachtStatusStats,
  getOpdrachtStatuses,
  getDisciplineLabel,
  getUserRoles,
  userHasScopes,
  getWerkbak,
  getAdvSearch,
} from '../redux/selectors'
import {
  searchOpdrachtStatus,
  downloadOpdrachtQuery,
} from '../redux/actions/opdrachtstatus'
import {
  searchWerkbakken,
} from '../redux/actions/werkbakken'
import { setAdvSearch } from '../redux/actions/page'
import { fetchValuelists } from '../redux/actions/valuelists'
import {
  AannemerFilterCell,
  DateFilterCell,
  DateTypeProvider,
  DisciplineFilterCell,
  VersieFilterCell,
  NetbeheerderFilterCell,
  OpdrachtLinkProvider,
  StatusFilterCell,
} from './Cells'

import {
  Loading
} from './Loading'

import Werkbakken from './Werkbakken'
import { Glyphicon } from 'react-bootstrap'

const VIRTUAL_PAGE_SIZE = 100
const DELAY = 500 // ms

const fromDetails = prop => row => row.details ? row.details[prop] : ''

function FilterCell(props) {
  switch (props.column.name) {
  case 'creatieDatum': // intentional fall through
  case 'changed':
    return <DateFilterCell {...props} />
  case 'netbeheerder':
    return <NetbeheerderFilterCell {...props} />
  case 'aannemer':
    return <AannemerFilterCell {...props} />
  case 'discipline':
    return <DisciplineFilterCell {...props} />
  case 'versieNummer':
    return <VersieFilterCell {...props} />
  case 'statusText':
    return <StatusFilterCell {...props} />
  default:
    return <TableFilterRow.Cell {...props} />

  }
}

function ZoekenPanel(props) {
  return (
    <Panel bsStyle="primary" style={{ margin: '2px' }}>
      <Panel.Heading>
        <Row>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <Panel.Title>
              <strong>Uitgebreid zoeken</strong>
            </Panel.Title>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}>
            <Glyphicon title="Exporteer opdrachten" style={{ cursor:'pointer', color: '#fff' }} className="pull-right" glyph="export" onClick={() => props.download()}/>
          </Col>
        </Row>
      </Panel.Heading>
      <Panel>
        {props.children}
      </Panel>
      <Panel.Footer>
        { props.stats && props.stats.count >= 0 && `${props.stats.count} ${props.stats.count === 1 ? 'resultaat' : 'resultaten'} gevonden.`}
      </Panel.Footer>
    </Panel>
  )
}

export class UitgebreidZoekenView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      display: false,
      columns: [
        { name: 'opdrachtId', title: 'Opdracht ID' },
        { name: 'clusterId', title: 'Cluster ID' },
        { name: 'clusterCodeNB', title: 'Cluster Code', getCellValue: fromDetails('clusterCodeNB') },
        { name: 'postcode', title: 'Postcode', getCellValue: fromDetails('postcode') },
        { name: 'plaats', title: 'Woonplaats', getCellValue: fromDetails('plaats')  },
        { name: 'netbeheerder', title: 'Netbeheerder' },
        { name: 'aannemer', title: 'Aannemer' },
        { name: 'versieNummer', title: 'Versie' },
        { name: 'discipline', title: 'Discipline', getCellValue: this.props.getDisciplineLabel },
        { name: 'creatieDatum', title: 'Creatiedatum', getCellValue: fromDetails('creatieDatum')  },
        { name: 'changed', title: 'Mutatiedatum' },
        { name: 'proces', title: 'Proces' },
        { name: 'statusText', title: 'Status' },
      ],
      tableColumnExtensions: [
        { columnName: 'statusText', width: 300 }
      ],
      filteringStateColumnExtensions: [
        { columnName: 'statusText', filteringEnabled: false }
      ],
      hiddenColumnNames: ['netbeheerder', 'aannemer', 'proces', 'clusterCodeNB'],
      loading: false,
      skip: 0,
      filters: [
        { columnName: 'statusText', value: 'open' }
      ],
      rows: [],
      init: false,
    }
  }

  componentDidMount() {
    if (this.props.roles && this.props.roles.length > 0) {
      this.props.fetchValuelists()
      this.showColumns()
    }
    const { filters } = this.props.advSearch

    if (filters && filters.length > 0) {
      this.setState(this.props.advSearch)
    } else {
      this.setState({ init:true })
    }
  }

  componentDidUpdate(prevProps) {
    const rowsChanged = prevProps.rows !== this.props.rows

    if (rowsChanged) {
      const { skip, limit, count } = this.props.stats
      const totalRowCount = count //Math.min(count, MAX_ROWS)
      const pageSize = Math.min(VIRTUAL_PAGE_SIZE, count)

      this.setState({ rows:this.props.rows, count,  skip, limit, pageSize,  totalRowCount, loading: false })
    }

    const { roles } = this.props

    if (prevProps.roles !== roles) {
      this.props.fetchValuelists()
      this.showColumns()
    }
  }

  componentWillUnmount() {
    this.props.setAdvSearch(this.state)
  }

  showColumns = () => {
    const { roles } = this.props
    const hiddenColumnNames = []

    if (roles.some(r => r.includes('Aannemer')) && !roles.some(r=>r.includes('Samenwerkingsverband'))) {
      hiddenColumnNames.push('aannemer')
    }
    if (roles.includes('Netbeheerder')) {
      hiddenColumnNames.push('netbeheerder')
    }
    if (!roles.includes('Developer')) {
      hiddenColumnNames.push('proces', 'clusterCodeNB')
    }
    this.setState({ roles, hiddenColumnNames })
  }

  changeFilters = (filters, cb) => this.setState(state => {
    const [wbFilter] = state.filters.filter(f => f.columnName === 'werkbak')
    const newFilters = filters.filter(f => f.columnName !== 'werkbak')

    if (wbFilter) newFilters.push(wbFilter)

    return ({ filters:newFilters, rows: [], filtersChanged: true })
  }, cb)

  getRemoteRows = debounce(query => {
    this.setState(state => {
      if (state.filtersChanged) {
        query.skip = 0
        query.limit = Math.max(VIRTUAL_PAGE_SIZE, query.limit)
      }
      this.props.searchOpdrachtStatus(query)

      if (this.props.userHasScopes(['show:werkbakken'])) {
        this.props.searchWerkbakken(query)
      }

      return { loading: true, filtersChanged: false }
    })
  }, DELAY)

  getRows = (skip, take) => {
    const limit = take === 0 ? VIRTUAL_PAGE_SIZE : take
    const { filters } = this.state
    const query = { filters, skip, limit }

    this.getRemoteRows(query)
  }

  filterWerkbak = werkbak =>  {
    const filters = this.state.filters.filter(f => f.columnName !== 'werkbak')

    if (werkbak)
      filters.push({ columnName: 'werkbak', value: werkbak, operator: 'equals' })

    this.setState({ filters, rows: [], filtersChanged: true, selectedWerkbak: werkbak }, () => this.getRows(0,0))
  }

  download = () => {
    const { filters } = this.state
    let filename = ['DSP Uitgebreid zoeken']
    const werkbak = filters
      .filter(f => f.columnName === 'werkbak')
      .map(f => this.props.getWerkbak(f.value).title)

    if (werkbak.length > 0) {
      filename = filename.concat(werkbak)
    }

    filename = filename.join(' ') + '.csv'

    this.props.downloadOpdrachtQuery(this.state.filters, filename)
  }


  changeExpandedDetails = expandedRowIds => this.setState({ expandedRowIds })

  render() {
    const {
      columns, tableColumnExtensions,
      loading, skip, totalRowCount,
      pageSize,
      rows,
      hiddenColumnNames,
      selectedWerkbak,
      init,
    } = this.state

    return (
      init && <ZoekenPanel stats={this.props.stats} download={this.download}>
        { this.props.userHasScopes(['show:werkbakken'])  &&
        <Werkbakken onClick={this.filterWerkbak} selected={selectedWerkbak}/>
        }
        <Grid
          noDataText="Geen data"
          rows={rows}
          columns={columns}
          getRowId={row => row.opdrachtId}>

          <FilteringState
            filters={this.state.filters}
            onFiltersChange={this.changeFilters}
            columnExtensions={this.state.filteringStateColumnExtensions}
          />

          <DateTypeProvider for={['changed', 'creatieDatum']} />

          <OpdrachtLinkProvider for={['opdrachtId']} />

          <VirtualTableState
            loading={loading}
            totalRowCount={totalRowCount}
            pageSize={pageSize}
            skip={skip}
            getRows={this.getRows}

          />
          <VirtualTable
            messages={{ noData:'Geen data' }}
            columnExtensions={tableColumnExtensions}/>
          <TableHeaderRow />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}/>
          <TableFilterRow
            cellComponent={FilterCell} />
        </Grid>
        { loading && <Loading /> }
      </ZoekenPanel>
    )
  }
}



const mapStateToProps = state => ({
  stats: getOpdrachtStatusStats(state),
  rows : getOpdrachtStatuses(state),
  roles: getUserRoles(state) || [],
  getDisciplineLabel: ({ discipline }) => getDisciplineLabel(state, discipline),
  userHasScopes: userHasScopes(state),
  getWerkbak: code => getWerkbak(state, code),
  advSearch: getAdvSearch(state),
})

export default connect(mapStateToProps, {
  searchOpdrachtStatus,
  searchWerkbakken,
  downloadOpdrachtQuery,
  fetchValuelists,
  setAdvSearch,
})(UitgebreidZoekenView)
