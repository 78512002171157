import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  FormGroup,
  HelpBlock,
  FormControl
} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { userHasScopes } from '../redux/selectors'
import { importRules } from '../redux/actions/rules'

class UploadForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      file: ''
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => (acceptedFiles.length > 0) && this.setState({ file:acceptedFiles[0] })

  readFile = () => new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () =>  resolve(reader.result)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')

    reader.readAsBinaryString(this.state.file)
  })

  onImport = () => {
    const { file } = this.state
    const ENTRY_LENGTH = 3

    if (file && file.name) {
      this.readFile()
        .then(csv => {
          const rules = csv.split('\n')
            .slice(1)
            .map(line => line.split(','))
            .filter(arr => arr.length === ENTRY_LENGTH)
            .map(arr => {
              const rule = { postcode: JSON.parse(arr[0]),
                versieNummer: JSON.parse(arr[1]),
                // eslint-disable-next-line no-magic-numbers
                aannemer: JSON.parse(arr[2]),
                opdrachtnemer: this.props.selectedOpdrachtnemer }

              return rule
            })

          this.props.importRules(rules)
        })
        .then(() => this.setState({ file: '' }))
    }
  }

  render() {
    const { file } = this.state

    return (

      this.props.show
        ?  <div className="static-modal">
          <Modal.Dialog>
            <Modal.Header>
              <Modal.Title>Uploaden</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <FormGroup controlId="csv">
                <br/>
                <Dropzone onDrop={this.onDrop} multiple={false} name="csv"
                  className="form-control"
                  style={{ height: '100%' }}
                  activeClassName="primary" acceptClassName="success" rejectClassName="error" >

                  <HelpBlock>Sleep hier uw csv naartoe</HelpBlock>
                  {
                    file.name &&
                      <div>Geselecteerd: <strong>{file.name}</strong></div>
                  }
                </Dropzone>
                <FormControl.Feedback />
              </FormGroup>
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={this.props.onClose}>Sluiten</Button>
              <Button bsStyle="success" className="pullRight" onClick={this.onImport}>Importeren</Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
        : false
    )}
}

UploadForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectedOpdrachtnemer: PropTypes.string
}

UploadForm.defaultProps = {
  show: false
}

const mapStateToProps = state => ({
  userHasScopes: userHasScopes(state)
})

export default connect(mapStateToProps, {
  importRules,
})(UploadForm)
