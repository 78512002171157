import React from 'react'
import { connect } from 'react-redux'
import {
  DropdownButton,
  MenuItem,
} from 'react-bootstrap'
import {
  selectParty
} from '../redux/actions/page'
import {
  getParties,
  getSelectedParty,
} from '../redux/selectors'

export function PartySelector({ parties, selectedParty, selectParty }) {

  return (

    <DropdownButton id="discipline" title={selectedParty || 'DSP'} onSelect={selectParty} bsSize="small" >
      { parties.map(p =>
        <MenuItem key={p} eventKey={p} active={selectedParty === p}>{p}</MenuItem>
      )}
    </DropdownButton>

  )
}

const mapStateToProps = state => ({
  parties       : getParties(state) || [],
  selectedParty : getSelectedParty(state),
})

export default connect(mapStateToProps, {
  selectParty

})(PartySelector)
