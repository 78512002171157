import React from 'react'
import { connect } from 'react-redux'
import {
  Getter,
} from '@devexpress/dx-react-core'
import {
  FilteringState,
  SortingState,
  EditingState,
  IntegratedFiltering,
  IntegratedSorting,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableEditColumn,
  TableEditRow,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-bootstrap3'
import { Button, ButtonToolbar, Panel, DropdownButton, MenuItem } from 'react-bootstrap'
import { Glyphicon } from 'react-bootstrap'
import { fetchActiveRules, fetchDraftRules, createRule, updateRule, deleteRule, activateRules,
  validateRules, resetRules, exportRules, fetchOpdrachtnemers } from '../redux/actions/rules'
import { selectOpdrachtnemer } from '../redux/actions/page'
import { getRules, getSelectedOpdrachtnemer, getOpdrachtnemers, userHasScopes, getUserParty } from '../redux/selectors'
import DefaultsForm  from './DefaultsForm'
import UploadForm from './UploadForm'

const getRowId = row => {
  return row._id
}

const validateRule = rule => {
  return rule !== undefined && rule.postcode !== undefined && rule.versieNummer !== undefined && rule.aannemer !== undefined
      && rule.postcode !== '' && rule.versieNummer !== '' && rule.aannemer !== ''
}

const editMessages = {
  addCommand: 'Toevoegen',
  editCommand: 'Aanpassen',
  deleteCommand: 'Verwijderen',
  commitCommand: 'Opslaan',
  cancelCommand: 'Annuleren',
}

const CommandIconButton = ({ title, glyph, onExecute }) =>
  <Button style={{ border:'0px', marginTop: '3px' }}  title={title} onClick={onExecute}><Glyphicon glyph={glyph} /></Button>

const commandComponents = {
  add: ({ onExecute }) => <CommandIconButton title='Toevoegen' glyph='plus' onExecute={onExecute}/>,
  edit: ({ onExecute }) => <CommandIconButton title='Bewerken' glyph='edit' onExecute={onExecute}/>,
  delete: ({ onExecute }) => <CommandIconButton title='Verwijderen' glyph='trash' onExecute={onExecute}/>,
  commit: ({ onExecute }) => <CommandIconButton title='Opslaan' glyph='ok' onExecute={onExecute}/>,
  cancel: ({ onExecute }) => <CommandIconButton title='Annuleren' glyph='floppy-remove' onExecute={onExecute}/>
}

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id]

  return (
    <CommandButton
      onExecute={onExecute}
    />
  )
}

export class SwvView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      columns: [
        { name: 'postcode', title: 'Postcode' },
        { name: 'versieNummer', title: 'Versie' },
        { name: 'aannemer', title: 'Aannemer' },
      ],
      rightFixedColumns: [TableEditColumn.COLUMN_TYPE],
      editingRowIds:[],
      addedRows: [],
      draftMode: false,
      showModal: false,
      showUpload: false
    }

    this.commitChanges = this.commitChanges.bind(this)
    this.changeEditingRowIds = this.changeEditingRowIds.bind(this)
    this.addedRowsChange = this.addedRowsChange.bind(this)
    this.invalidEditedRowIds = []
    this.invalidAddedRows = []
  }

  componentDidMount() {
    this.props.fetchOpdrachtnemers()
  }

  componentDidUpdate(prevProps) {
    const { opdrachtnemers, selectedOpdrachtnemer } = this.props

    if(prevProps.opdrachtnemers !== opdrachtnemers && selectedOpdrachtnemer === '') {
      const selectedOpdrachtnemer = opdrachtnemers && opdrachtnemers.length === 1 ? opdrachtnemers[0] : ''

      this.props.selectOpdrachtnemer(selectedOpdrachtnemer)
      this.fetchRules()
    }
  }

  fetchRules = () => this.state.draftMode ? this.props.fetchDraftRules() : this.props.fetchActiveRules()

  commitChanges = ({ added, changed, deleted }) => {
    if(added) {
      added.forEach(rule => {
        if(validateRule(rule)) {

          if(this.props.selectedOpdrachtnemer)
            rule.opdrachtnemer = this.props.selectedOpdrachtnemer

          this.props.createRule(rule)
        }else{
          this.invalidAddedRows.push(added)
        }
      })
    }

    if(changed) {
      let rule = this.props.rows.find((row) => changed[row._id] !== undefined)

      if(rule) {
        rule = { ...rule, ...changed[rule._id] }

        if(validateRule(rule)) {
          this.props.updateRule(rule)
        }else{
          this.invalidEditedRowIds.push(rule._id)
        }
      }
    }

    if(deleted) {
      deleted.forEach(id => this.props.deleteRule(id))
    }
  }

  changeEditingRowIds = (editingRowIds) => {
    if (this.invalidEditedRowIds.length) {
      this.setState({ editingRowIds: [...editingRowIds, ...this.invalidEditedRowIds] })
      this.invalidEditedRowIds.length = 0
    } else {
      this.setState({ editingRowIds })
    }
  }

  addedRowsChange = (addedRows) => {
    if (this.invalidAddedRows.length) {
      this.setState({ addedRows: [...addedRows, ...this.invalidAddedRows] })
      this.invalidAddedRows.length = 0
    } else {
      this.setState({ addedRows })
    }
  }

  activate = () => {
    if(this.state.addedRows.length === 0 && this.state.editingRowIds.length === 0)
      this.props.activateRules()
  }

  validate = () => {
    if(this.state.addedRows.length === 0 && this.state.editingRowIds.length === 0)
      this.props.validateRules()
  }

  toggleDraftMode = () => this.setState({ draftMode:!this.state.draftMode }, this.fetchRules)

  onSelectOpdrachtnemer = (opdrachtnemer) => {
    this.props.selectOpdrachtnemer(opdrachtnemer)
    this.fetchRules()
  }

  renderButtons = () => {
    const { draftMode } = this.state
    const { opdrachtnemers, selectedOpdrachtnemer, userHasScopes } = this.props
    const enableDraft = userHasScopes(['create:swv', 'edit:swv', 'delete:swv'])
    const showBeheer = userHasScopes(['show:swv-beheer'])
    const showDraftButtons = draftMode && selectedOpdrachtnemer !== '' && selectedOpdrachtnemer !== 'Nieuw'

    return (
      <div className='row' style={{ paddingBottom: '10px' }}>
        <div className="col-md-12">
          <ButtonToolbar className='pull-right'>
            {showDraftButtons &&
            <React.Fragment>
              <Button bsSize="small"  title="Exporteren" onClick={() => this.props.exportRules(!draftMode)}><Glyphicon glyph="export"/></Button>
              <Button bsSize="small"  title="Importeren" onClick={() => this.setState({ showUpload: true })}><Glyphicon glyph="import"/></Button>
              <Button bsStyle="success" bsSize="small" title="Activeren" onClick={this.activate}><Glyphicon glyph="play"/></Button>
              <Button bsStyle="warning" bsSize="small" title="Valideren" onClick={this.validate}><Glyphicon glyph="saved"/></Button>
              <Button bsStyle="danger" bsSize="small"  title="Reset" onClick={this.props.resetRules}><Glyphicon glyph="retweet"/></Button>
            </React.Fragment>}
            <Button bsSize="small" title="Defaults bewerken" onClick={() => this.setState({ showModal:true })}><Glyphicon glyph="cog"/></Button>
            { draftMode
              ? <Button bsSize="small" onClick={this.toggleDraftMode}>Actieve versie tonen</Button>
              : <Button bsSize="small" disabled={enableDraft} onClick={this.toggleDraftMode}>Draft versie tonen</Button>}
            { showBeheer && <DropdownButton
              bsSize="small"
              id="input-dropdown"
              title={selectedOpdrachtnemer ? selectedOpdrachtnemer : 'Kies'}
              onSelect={this.onSelectOpdrachtnemer}>
              { opdrachtnemers.map(v =>
                <MenuItem key={v} eventKey={v}>{v}</MenuItem>
              )}
            </DropdownButton> }
          </ButtonToolbar>
        </div>
      </div>
    )
  }

  render() {
    const { columns, rightFixedColumns, editingRowIds, addedRows, draftMode, showModal, showUpload } = this.state
    const { opdrachtnemers, rows, selectedOpdrachtnemer } = this.props
    const showDraftButtons = draftMode && selectedOpdrachtnemer !== '' && selectedOpdrachtnemer !== 'Nieuw'

    return (
      <div>
        {this.renderButtons()}

        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}>

          <FilteringState />

          <EditingState onCommitChanges={this.commitChanges}
            editingRowIds={editingRowIds}
            addedRows={addedRows}
            onAddedRowsChange={this.addedRowsChange}
            onEditingRowIdsChange={this.changeEditingRowIds}/>

          <SortingState
            defaultSorting={[{ columnName:'postcode' }]}
          />

          <IntegratedFiltering/>
          <IntegratedSorting/>

          <Table messages={{ noData:'Geen data' }} />
          <TableHeaderRow showSortingControls/>
          <TableFilterRow/>

          { showDraftButtons && <TableEditRow/> }
          { showDraftButtons && <TableEditColumn showAddCommand showEditCommand showDeleteCommand messages={editMessages} commandComponent={Command}/> }
          { showDraftButtons && <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              const result = [
                ...tableColumns.filter(c => c.type !== TableEditColumn.COLUMN_TYPE),
                { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 80 }
              ]

              return result
            }}
          />}

          <TableFixedColumns rightColumns={rightFixedColumns}/>
        </Grid>
        <DefaultsForm show={showModal} draftMode={draftMode}
          selectedOpdrachtnemer= {selectedOpdrachtnemer}
          onSelectOpdrachtnemer={this.onSelectOpdrachtnemer}
          opdrachtnemers={opdrachtnemers}
          onClose={() => this.setState({ showModal: false })}/>
        <UploadForm show={showUpload}
          onClose={() => this.setState({ showUpload: false })}
          selectedOpdrachtnemer= {selectedOpdrachtnemer}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  rows: getRules(state),
  opdrachtnemers: getOpdrachtnemers(state),
  selectedOpdrachtnemer: getSelectedOpdrachtnemer(state),
  userHasScopes: userHasScopes(state),
  party: getUserParty(state)
})

function SamenwerkingsVerbandPanel(props) {
  return (
    <div className="container">
      <Panel bsStyle="primary">
        <Panel.Heading>
          <Panel.Title toggle>
            <strong>Samenwerkingsverband onderhouden</strong>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <SwvView {...props} />
        </Panel.Body>
      </Panel>
    </div>
  )
}

export default connect(mapStateToProps, {
  fetchActiveRules,
  fetchDraftRules,
  createRule,
  updateRule,
  deleteRule,
  activateRules,
  validateRules,
  resetRules,
  selectOpdrachtnemer,
  exportRules,
  fetchOpdrachtnemers
})(SamenwerkingsVerbandPanel)
