import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Glyphicon } from 'react-bootstrap'
import { connect } from 'react-redux'
import React from 'react'

import PropTypes from 'prop-types'

import { downloadCertificate } from '../redux/actions/certificates'
import { formatDateTime  } from '../util'

import { deleteCertificate } from '../redux/actions/certificates'
import { selectCertificate } from '../redux/actions/page'
import { getSelectedCertificateId } from '../redux/selectors'

export function CertificateRow(props)  {
  const certificate = props.certificate
  const { _id, name, type, updated  } = certificate

  const onSelect = e => {e.preventDefault(); props.selectCertificate(_id)   }
  const onDelete = e => {e.preventDefault(); props.disabled || props.deleteCertificate(certificate) }
  const download = e => {e.preventDefault(); props.downloadCertificate(certificate)}

  return (
    <tr onClick={onSelect} className={(props.selected) ? 'selected' : ''}>
      <td>{name}</td>
      <td>{type}</td>
      <td>{formatDateTime(updated)}</td>

      <td align="center" style={{ width: '32px' }}>
        <a href={`/certificates/download(${_id})`} title="Certificaat downloaden" onClick={download}>
          <FontAwesomeIcon icon="download" />
        </a>
      </td>

      <td align="center" style={{ width: '32px' }}>
        <a href={`certificates/delete(${_id})`} style={props.disabled ? { color:'#cecece' } : {}} title="Verwijderen" onClick={onDelete}>
          <Glyphicon glyph="remove" />
        </a>
      </td>

    </tr>
  )
}

CertificateRow.propTypes = {
  selectCertificate    : PropTypes.func.isRequired,
  deleteCertificate    : PropTypes.func,
  downloadCertificate  : PropTypes.func,
  certificate          : PropTypes.shape({
    _id     : PropTypes.string.isRequired,
    name    : PropTypes.string.isRequired,
    type    : PropTypes.string,
  })
}

const mapStateToProps = () => (state, ownProps) => ({
  selected: getSelectedCertificateId(state) === ownProps.certificate._id
})

export default connect(mapStateToProps, {
  downloadCertificate,
  deleteCertificate,
  selectCertificate,
})(CertificateRow)
