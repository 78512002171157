import {
  Button,
  Panel,
} from 'react-bootstrap'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EndpointInput from './EndpointInput'

class EndpointsForm extends Component {
  static propTypes = {
    editable: PropTypes.bool,
    endpoints: PropTypes.object,
    endpointNames: PropTypes.array.isRequired,
  }
  static defaultProps = {
    editable: false,
    title: 'Endpoints',
    endpoints: {},
    endpointNames: ['']
  }
  state = {
    endpoints: {},
  }

  updaters = {}

  componentDidUpdate(prevProps, prevState) {
    const props = this.props


    if (prevProps.endpoints !== props.endpoints) {
      const endpoints = props.endpoints || {}

      this.setState({ endpoints })
    }
  }

  getEndpoint = name => this.state.endpoints[name] || { url:'', action:'' }

  createUpdater = name => (prop, value = '') => {
    const newEndpoint = { ...this.getEndpoint(name), [prop]:value }
    const endpoints = { ...this.state.endpoints, [name]:newEndpoint }

    this.setState({ endpoints })
  }

  save = () => {
    this.props.save(this.state.endpoints)
    this.setState({ editmode:false })
  }

  cancel = () => this.setState({ editmode:false, endpoints:this.props.endpoints })

  getUpdater = name => {
    if (!this.updaters[name]) {
      this.updaters[name] = this.createUpdater(name)
    }

    return this.updaters[name]
  }

  render() {
    const props = this.props
    const { endpointNames } = this.props
    const { editmode } = this.state
    const disabled = !editmode
    const readOnly = !editmode

    return (
      <Panel eventKey="endpoints" defaultExpanded>
        <Panel.Heading>
          <Panel.Title toggle>{props.title}</Panel.Title>
        </Panel.Heading>

        <Panel.Collapse>
          <Panel.Body>
            <div style={{ textAlign: 'right' }}>
              {disabled && <Button bsSize="small" disabled={!props.editable} onClick={() => this.setState({ editmode:true })}>Wijzigen</Button>}
              {editmode && <Button bsSize="small" onClick={this.cancel}>Annuleren</Button> }
            &nbsp;<Button bsStyle="success" bsSize="small" onClick={this.save} disabled={disabled}>Opslaan</Button>
            </div>

            <form>

              {endpointNames.map(name => {
                const { url, action } = this.getEndpoint(name)

                if (this.props.filterEmpty) {
                  if (!url && !action)
                    return false
                }

                return <EndpointInput key={name} label={name} url={url} action={action} onChange={this.getUpdater(name)} readOnly={readOnly}/>
              })
              }
            </form>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    )
  }
}

export default EndpointsForm
