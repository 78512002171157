
import React from 'react'
import './loading.css'

export function Loading() {
  return (
    <div className="loading-shading">
      <span className="glyphicon glyphicon-refresh loading-icon" />
    </div>

  )
}
