import React from 'react'
import { Router, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { createHasScope, LoginPage } from './auth/LoginUtil'
import Callback from './auth/Callback'
import { handleAuthentication } from './redux/actions/auth'
import Connectivity from './connectivity'
import MaintenanceView from './maintenance'
import NavMenu from './nav/NavMenu'
import OpdrachtStatusView from './opdrachtstatus/OpdrachtStatusView'
import UitgebreidZoekenView from './uitgebreidzoeken'
import Tracker from './tracker'
import SWV from './swv'
import history from './history'

import {
  isAuthenticated,
  userHasScopes,
} from './redux/selectors'

export function makeMainRoutes(props)  {
  const { isAuthenticated, userHasScopes, handleAuthentication } = props
  const auth = { isAuthenticated, userHasScopes }
  const hasMaintenance = createHasScope(auth, 'show:maintenance')
  const hasStatus = createHasScope(auth, 'show:opdrachtstatus')
  const hasConnectivity = createHasScope(auth, 'show:connectivity')
  const hasTracker = createHasScope(auth, 'show:tracker')
  const hasAdvSearch = createHasScope(auth, 'show:advsearch')
  const hasSWV = createHasScope(auth, 'show:swv')

  return (
    <Router history={history}>
      <div>
        <Route path="/" render={props => <NavMenu {...props} />} />
        <Route exact path="/" render={() => (
          <Redirect to="/home" />
        )} />
        <Route exact path="/home" render={() => {
          if (auth.userHasScopes([ 'show:opdrachtstatus' ]))
            return <Redirect to="/status" />

          if (auth.userHasScopes([ 'show:connectivity' ]))
            return <Redirect to="/connections" />

          return <LoginPage />
        }} />

        <Route path="/status/:query?" render={hasStatus(OpdrachtStatusView)} />

        <Route path="/zoeken/" render={hasAdvSearch(UitgebreidZoekenView)} />

        <Route path="/maintenance" render={hasMaintenance(MaintenanceView)}/>

        <Route path="/connections" render={hasConnectivity(Connectivity)} />
        <Route path="/tracker" render={hasTracker(Tracker)} />
        <Route path="/samenwerking" render={hasSWV(SWV)} />

        <Route path="/callback"
          render={props => {
            if (/access_token|id_token|error/.test(props.location.hash)) {
              handleAuthentication()
            }

            return <Callback {...props} />
          }} />
      </div>
    </Router>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: () => isAuthenticated(state),
  userHasScopes  : userHasScopes(state),
})

export default connect(mapStateToProps, { handleAuthentication })(makeMainRoutes)
