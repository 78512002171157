import React from 'react'

import { connect } from 'react-redux'

import { getWerkbakken } from '../redux/selectors'


import {
  Button, Badge,
} from 'react-bootstrap'


function Werkbakken({ selected, werkbakken, onClick }) {

  return (

    <div style={{ marginTop: '10px', marginBottom: '10px' }} >
      {
        werkbakken.map(row => (
          <Button key={row.code}  bsStyle={(selected === row.code) ? 'primary' : undefined}  onClick={() => {onClick(selected === row.code ? undefined : row.code)}} style={{ margin: '2px', width: '270px', textAlign: 'left' }}>
            {row.title} <Badge style={(selected === row.code) ? ({}) : ({ background:'gray' }) } pullRight>{row.count > 0 ? row.count : ''}</Badge>
          </Button>
        ))}
    </div>

  )
}

const mapStateToProps = (state) =>  ({
  werkbakken : getWerkbakken(state),
})

export default connect(mapStateToProps)(Werkbakken)
